import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Routes from "./Routes/index";
import { jwtDecode } from "jwt-decode";

// Import Scss
import "./assets/scss/theme.scss";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

// Firebase
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEYKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authHC");

    if (token) {
      const decodedToken = jwtDecode(token); // Correct function name
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      // If the token has expired
      if (currentTime > expirationTime) {
        localStorage.removeItem("authHC");
        navigate("/login"); // Redirect to login page
      }
    }
  }, [navigate]); // The effect will run when navigate changes

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
